import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import logo from '../assets/images/logo.png';
import config from '../../config';

// OLD BIO
// `
//     {/*<section id="two" className="wrapper alt style2">*/}
//     {/*  <section className="wrapper special">*/}
//     {/*    <div className="inner">*/}
//     {/*      <h2>*/}
//     {/*        Adam and Joe took their two unique professions and joined them into one to create A&J Dock Builders.*/}
//     {/*      </h2>*/}
//     {/*      <h2>*/}
//     {/*        Adam is a custom fence builder for over 20 years and Joe is a talented metal fabricator and crane mechanic.*/}
//     {/*      </h2>*/}
//     {/*      <h2>*/}
//     {/*        Together, their skills will create the best, most durable docks for Candlewood Lake.*/}
//     {/*      </h2>*/}
//     {/*    </div>*/}
//     {/*  </section>*/}
//     {/*</section>*/}
//`

const IndexPage = () => {
  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          {
            config.headingType === "text" ?
              <h2>{config.headingText}</h2>
              :
              config.headingType === "logo" ?

                <div className="col-4"><span className="image fit"><img src={logo}
                                                                        alt={`${config.headingText}`}/></span></div>
                :
                null
          }
          <p>{config.subHeading}</p>
          <ul className="actions special">
            <li>
              <Scroll type="id" element="one">
                <a href="/#" className="button primary">
                  Explore
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </section>
      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <p>
              Experienced and licensed serving
            </p>
            <p>
              <b>Candlewood Lake</b>
            </p>
            <p>
              <b>Lake Waramaug</b>
            </p>
            <p>
              <b>Lake Lillinonah</b>
            </p>
            <p>
              <b>Lake Zoar</b>
            </p>
            <p>
              We use our own boats and trained professionals
            </p>
          </header>
        </div>
      </section>

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h2>Reviews</h2>
            <h5>Erin</h5>
            <blockquote>Adam and crew were professional and provided quality work</blockquote>
            <br/>
            <h5>Virginia</h5>
            <blockquote>They were friendly and professional and did excellent work</blockquote>
            <br/>
            <h5>Sina</h5>
            <blockquote>They performed an outstanding job</blockquote>
            <br/>
            <h5>Nanette</h5>
            <blockquote>I love the work that you do and am so grateful for your expertise! I look forward to working
              with you again in the near future as you made this project effortless
            </blockquote>
          </header>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
